// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news_media{
    background-color:#013B81;
    padding-top: 0px;
    padding-bottom: 30px;
    min-height: 240px;
}
.news_media h1{

    color: #fff;
    font-size: 4em;
    padding: 4rem
}



/* Give the document some structure. */


/* Create a responsive, centered, three-column grid. */
.news_media_card-container {
    margin: 0 auto;
	max-width: 1600px;
	align-items: start;
	display: grid;
    margin-top: 2rem;
	grid-gap: 16px;
	grid-template-columns: repeat(auto-fit, 300px);
	justify-content: center;
}

/* --- Optional styles (to make this pen look nice) --- */


.news_media_card {
	background-color: rgb(255, 255, 255);
	border-radius: 5px;
	color: rgb(0, 0, 0);
	padding: 24px 19px 16px 19px;

	text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.news_media_card:hover{
	transform: translateY(-0.5rem);
}
.news_media_card .span{
    color: red;
    padding-bottom: 2rem;
}

.news_media_card p{
	text-align: left;

}

@media (prefers-color-scheme: dark) {
	html {
		background-color: #111;
		color: #fff;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/news_media/news_media.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,oBAAoB;IACpB,iBAAiB;AACrB;AACA;;IAEI,WAAW;IACX,cAAc;IACd;AACJ;;;;AAIA,sCAAsC;;;AAGtC,sDAAsD;AACtD;IACI,cAAc;CACjB,iBAAiB;CACjB,kBAAkB;CAClB,aAAa;IACV,gBAAgB;CACnB,cAAc;CACd,8CAA8C;CAC9C,uBAAuB;AACxB;;AAEA,yDAAyD;;;AAGzD;CACC,oCAAoC;CACpC,kBAAkB;CAClB,mBAAmB;CACnB,4BAA4B;;CAE5B,kBAAkB;IACf,yCAAyC;AAC7C;AACA;CACC,8BAA8B;AAC/B;AACA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;CACC,gBAAgB;;AAEjB;;AAEA;CACC;EACC,sBAAsB;EACtB,WAAW;CACZ;AACD","sourcesContent":[".news_media{\r\n    background-color:#013B81;\r\n    padding-top: 0px;\r\n    padding-bottom: 30px;\r\n    min-height: 240px;\r\n}\r\n.news_media h1{\r\n\r\n    color: #fff;\r\n    font-size: 4em;\r\n    padding: 4rem\r\n}\r\n\r\n\r\n\r\n/* Give the document some structure. */\r\n\r\n\r\n/* Create a responsive, centered, three-column grid. */\r\n.news_media_card-container {\r\n    margin: 0 auto;\r\n\tmax-width: 1600px;\r\n\talign-items: start;\r\n\tdisplay: grid;\r\n    margin-top: 2rem;\r\n\tgrid-gap: 16px;\r\n\tgrid-template-columns: repeat(auto-fit, 300px);\r\n\tjustify-content: center;\r\n}\r\n\r\n/* --- Optional styles (to make this pen look nice) --- */\r\n\r\n\r\n.news_media_card {\r\n\tbackground-color: rgb(255, 255, 255);\r\n\tborder-radius: 5px;\r\n\tcolor: rgb(0, 0, 0);\r\n\tpadding: 24px 19px 16px 19px;\r\n\r\n\ttext-align: center;\r\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n}\r\n.news_media_card:hover{\r\n\ttransform: translateY(-0.5rem);\r\n}\r\n.news_media_card .span{\r\n    color: red;\r\n    padding-bottom: 2rem;\r\n}\r\n\r\n.news_media_card p{\r\n\ttext-align: left;\r\n\r\n}\r\n\r\n@media (prefers-color-scheme: dark) {\r\n\thtml {\r\n\t\tbackground-color: #111;\r\n\t\tcolor: #fff;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
