// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dodeca, .dodeca div {
    margin: 0 auto;
    transform-origin: 50% 50%;
    overflow: hidden;
    width: 400px;
    height: 400px;
  }
  .dodeca {
    transform: rotate(120deg);
  }
  .dode1 {
    transform: rotate(-60deg);
  }
  .dode2 {
    transform: rotate(-60deg);
  }
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/Components/careers/careers.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,YAAY;IACZ,aAAa;EACf;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,yBAAyB;EAC3B","sourcesContent":[".dodeca, .dodeca div {\r\n    margin: 0 auto;\r\n    transform-origin: 50% 50%;\r\n    overflow: hidden;\r\n    width: 400px;\r\n    height: 400px;\r\n  }\r\n  .dodeca {\r\n    transform: rotate(120deg);\r\n  }\r\n  .dode1 {\r\n    transform: rotate(-60deg);\r\n  }\r\n  .dode2 {\r\n    transform: rotate(-60deg);\r\n  }\r\n  \r\n  \r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
