import React, { useEffect ,useState} from 'react';
import Navbar from '../nav/Navbar';
import './gallery.css'
import g1 from '../img/g1.jpg'
import g2 from '../img/g2.jpg'
import g3 from '../img/g3.jpg'
import g4 from '../img/g4.jpg'
import g5 from '../img/g5.jpg'
import g6 from '../img/g6.jpg'
import g7 from '../img/g7.jpg'
import g8 from '../img/g8.jpg'
import g9 from '../img/g9.jpg'
import g10 from '../img/g10.jpg'
import g11 from '../img/g11.jpg'
import g12 from '../img/g12.jpg'
import g13 from '../img/g13.jpg'
import g14 from '../img/g14.jpg'
import g15 from '../img/g15.jpg'
import g16 from '../img/g16.jpg'
import Footer from '../footer/Footer';
const images = [
    { src: g1, alt: "Image 01" },
    { src: g2, alt: "Image 02" },
    { src: g9, alt: "Image 09" },
    { src: g4, alt: "Image 04" },
    { src: g3, alt: "Image 03" },
    { src: g5, alt: "Image 05" },
    { src: g8, alt: "Image 08" },
    { src: g7, alt: "Image 07" },
    { src: g10, alt: "Image 10" },
    { src: g13, alt: "Image 13" },
    { src: g14, alt: "Image 14" },
    { src: g12, alt: "Image 12" },
    { src: g6, alt: "Image 06" },
    { src: g11, alt: "Image 11" },
    { src: g15, alt: "Image 15" },
    { src: g16, alt: "Image 16" }
  ];
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [lightboxImage, setLightboxImage] = useState("");
  const [lightboxAltImage, setLightboxAltImage] = useState("");

  // Function to open the lightbox with an image
  const openLightbox = (src, alt) => {
    setLightboxImage(src);
    setLightboxAltImage(alt);
  };

  // Function to close the lightbox
  const closeLightbox = () => {
    setLightboxImage("");
    setLightboxAltImage("");
  };
  return (
    <div>
      <Navbar />
      <div className='news_media' >
        <h1>Gallery</h1>
      </div>
     
        <div className='main_contact' style={{marginTop:"2rem"}}>
      
      <div className="gallery">
        {images.map((image, index) => (
          <button
            type="button"
            className="gallery__item"
            key={index}
            onClick={() => openLightbox(image.src, image.alt)}
          >
            <img className="gallery__image" src={image.src} alt={image.alt} />
          </button>
        ))}
      </div>

      {lightboxImage && (
        <div className="lightbox">
          <button
            type="button"
            className="lightbox__close-button"
            onClick={closeLightbox}
          >
            &times;
          </button>
          <img
            className="lightbox__image"
            src={lightboxImage}
            alt={lightboxAltImage}
          />
          <button
            type="button"
            className="lightbox__bg"
            onClick={closeLightbox}
          />
        </div>
      )}
    </div>
   
      <Footer />
    </div>

  );
};

export default ContactUs;
