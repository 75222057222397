import React,{useEffect} from 'react'
import Navbar from '../nav/Navbar';
import Footer from '../footer/Footer'
function Privacy_Policy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div>
      <Navbar/>
       <div class="termsAndConditions fadeIn">
      <h1 class="termsAndConditionsHeading">Privacy Policy</h1>
      <h4 class="spangleWelcome">Thank you for visiting Kaabil Finance</h4>
      <p class="termsParagraphIntro">This Privacy Policy ("Policy") outlines how 
Kaabil Finance ("Kaabil Finance," "we," "our," or "us") collects, uses, and protects any information 
("Information") that you provide to us when you use our Website or interact with us through other 
means.<br/><br/>
By accessing the Website or providing any Information to Kaabil Finance, you agree to the terms outlined 
in this Policy. Please read this Policy carefully along with our Terms and Conditions of Use

      </p>    
      <div class="serviceLeadingSection">
        <h4><span class="sn blue">1.</span><span class="st blue">Information Collection</span></h4>
        <p class="spl">Kaabil Finance collects various types of Information to provide and improve our services. This may 
include personal information such as your name, contact details, address, email address, phone number, 
and financial information. Additionally, we may collect information automatically through cookies and 
similar tracking technologies when you use our Website.</p>                                
      </div>

      <div class="serviceLeadingSection">
        <h4><span class="sn orange">2.</span><span class="st orange">Use of Information</span></h4>
        <p class="spl">We use the Information collected to operate the Website, provide you with details on our services, 
facilities, and programs, and communicate with you about promotional offers and schemes. The 
Information may also be used to personalize your experience, improve our products and services, and 
for internal record keeping.</p>                  
      </div>
      <div class="serviceLeadingSection">
        <h4><span class="sn lightGreen">3.</span><span class="st lightGreen">Cookies and Tracking Technologies</span></h4>
        <p class="spl">Kaabil Finance uses cookies and similar tracking technologies to track the activity on our Website and 
improve your user experience. Cookies are small files stored on your device that track your browsing 
behavior. You can choose to accept or decline cookies through your browser settings, although this may 
affect your ability to access certain features of our Website.</p>
      </div> 
       
      <div class="serviceLeadingSection">
        <h4><span class="sn purple">4.</span><span class="st purple">Information Sharing</span></h4>
        <p class="spl">Kaabil Finance does not sell, rent, or otherwise distribute your Information to third parties unless 
required to provide services to you, enable a transaction, comply with legal obligations, or as agreed to 
by you. We may share your Information with our affiliates, staff, or third parties with permitted access, 
subject to confidentiality obligations.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn yellow">5.</span><span class="st yellow">Third-Party Links</span></h4>
        <p class="spl">Our Website may contain links to third-party websites. This Privacy Policy does not apply to how 
information is handled by third parties or their websites. We encourage you to review the privacy 
policies of any third-party websites you visit.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn red">6.</span><span class="st red">Data Transfer and Legal Disclosure</span></h4>
        <p class="spl">Your Information may be transferred to and stored on servers located outside of your jurisdiction, 
including India, where data protection laws may differ. We may disclose your Information when 
necessary to comply with legal obligations, protect our rights or property, prevent wrongdoing, or ensure 
the safety of our users or the public.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn lineColorpink">7.</span><span class="st lineColorpink">Changes to this Policy</span></h4>
        <p class="spl">Kaabil Finance reserves the right to update or modify this Privacy Policy at any time. Any changes will be 
effective immediately upon posting on this page. We encourage you to review this Policy periodically for 
updates.</p>
      </div> 
     
    </div>
   

    <Footer/>
   
    </div>
  )
}

export default Privacy_Policy
