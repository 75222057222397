export const links = [
    // {
    //   name: "Loans",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       sublink: [
    //         { name: "Secure Business Loan", link: "/secure-business-loan" },
    //         { name: "Loan Against Property", link: "/loan-against-property" },
           

    //       ],
    //     }, 
    //   ],
    // },
    {
      name: "About",
      submenu: true,
      sublinks: [
        {
          sublink: [
            { name: "Who We Are", link: "/who-we-are" },
            // { name: "Our Presence", link: "/our-presence#OurPresence" },
            { name: "Our Vision", link: "/who-we-are#OurVision" },
            { name: "Our Values", link: "/who-we-are#OurValues" },
            { name: "Our Partners", link: "/who-we-are#OurPartners" },
          ],
        },
      ],
    },
   
  ];
  