import React,{useEffect,useState} from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import './careers.css'
function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
return (
    <div><Navbar/>
    <div className='news_media'>
        <h1>Careers</h1>
      </div>  

      <div className='main_contact'>
          <h1 id="colorRed">Join Our Team at Kaabil Finance</h1>


          <section class="main_contact_landing">

    <div class="main_contact_landing-text">
     
      <p className='BusiessLoan-para'>
      <b id="colorBlue">K - Kaabil Spirit</b> At Kaabil Finance, we embody the spirit of capability and ambition, constantly pushing 
boundaries and achieving remarkable milestones daily.
      </p>
      <p className='BusiessLoan-para'>
      <b id="colorBlue">A - Ambition to Grow</b> Join us on our journey of growth and innovation, where we strive to help you 
achieve your dreams and reach new heights in your career.
      </p>
      <p className='BusiessLoan-para'>
      <b id="colorBlue">A - Achieve Your Dreams</b> We revolutionize the finance sector with cutting-edge technology, 
streamlining processes and eliminating bureaucratic hurdles for a seamless experience.
      </p>
      <p className='BusiessLoan-para'>
      <b id="colorBlue">B - Bring Change</b> Be part of our team dedicated to bringing positive change to the lives of people across 
diverse communities.
      </p>
      <p className='BusiessLoan-para'>
      <b id="colorBlue">I - Innovative</b> Experience vibrant office spaces and a relaxed work culture that sparks creativity, 
fostering innovation and collaboration.

      </p>
      <p className='BusiessLoan-para'>
      <b id="colorBlue">L - Lead</b> Benefit from accessible leadership and supportive team leaders. Our flat hierarchy ensures 
direct communication and efficient decision-making.
      </p>
    </div>
    <div class="main_contact_landing-image">
    <div class="dodeca">
      <div class="dode1">
        <div class="dode2">
          <img src="https://img.freepik.com/free-photo/we-are-hiring-concept-collage_23-2149557309.jpg?w=360&t=st=1707414214~exp=1707414814~hmac=1c4ce1e006c9043f5a43256ed207b0e856351def3ec82aa80a095687a8ae5402" alt="" width="320" height="313" />
        </div>
      </div>
    </div>

      {/* <img src={Contact} alt="Gold loan" className='Bussiness_loan_logo'/> */}
    </div>
    
  </section>




  
        </div>
       
    <Footer/></div>
  )
}

export default Careers