import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Kaabil from "../img/kaabil.png";
import NavLinks from "./NavLinks";
const Navbar = () => {
  const [open, setOpen] = useState(false);
  

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    // style={{backgroundColor:"#0658a8",position:"fixed",top:"0",width:"100%",overflow:"hidden",zIndex:"100"}}
    <nav className={scrolling ? 'navbars scrolled' : 'navbar'}>
      <div className="flex items-center font-medium justify-between" style={{padding:"0px 2rem"}}>
        <div className="z-50 md:w-auto w-full flex justify-between" style={{backgroundColor:"#fff",padding:"0px 1.5rem", borderRadius:"0.3rem"}}>
        <Link to="/"><img src={Kaabil} alt="company logo" className="md:cursor-pointer" style={{height:"5rem",width:"13rem"}}  /></Link>
        
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 font-[Poppins] text-white font-bold">
          <li  className="hover-effect">
            <Link to="/" className="py-7 px-3 inline-block">
              Home
            </Link>
          </li>
          <li  className="hover-effect"> 
            <Link to="/secure-business-loan" className="py-7 px-3 inline-block">
            Product
            </Link>
          </li>
          <NavLinks />
          {/* <li  className="hover-effect"> 
            <Link to="/news-media" className="py-7 px-3 inline-block">
            News & Media
            </Link>
          </li> */}
          <li  className="hover-effect">
            <Link to="/careers" className="py-7 px-3 inline-block">
            Careers
            </Link>
          </li><li  className="hover-effect">
            <Link to="/contact-us" className="py-7 px-3 inline-block">
            Contact Us
            </Link>
          </li>
       
       
        </ul>

        
        {/* <div className="md:block hidden">
          <Button />
        </div> */}
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link to="/" className="py-7 px-3 inline-block">
              Home
            </Link>
          </li>
          <li  className="hover-effect"> 
            <Link to="/secure-business-loan" className="py-7 px-3 inline-block">
            Product
            </Link>
          </li>
          <NavLinks />
           {/* <li>
            <Link to="/news-media" className="py-7 px-3 inline-block">
            News & Media
            </Link>
          </li>*/}
          <li>
            <Link to="/careers" className="py-7 px-3 inline-block">
            Careers
            </Link>
          </li><li>
            <Link to="/contact-us" className="py-7 px-3 inline-block">
            Contact Us
            </Link>
          </li>
         
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
