import React from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import './news_media.css'
function NewsMedia() {
  return (
    <div>
        <Navbar/>
        <div className='news_media'>
          <h1>News And Media</h1>
        </div>

		<div className='about_us'>
      <section style={{marginTop:"3rem"}}>
      <h1>SCHEDULE OF CHARGES</h1>
      <div className="table-responsive">
      <table className="table table-bordered table-hover" style={{ textAlign: 'center' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }} width="10%"><b>S.No.</b></th>
            <th style={{ textAlign: 'center' }} width="45%"><b>Particulars</b></th>
            <th style={{ textAlign: 'center' }} width="45%"><b>Amount</b></th>
          </tr>
        </thead>
        <tbody id="annualTable">
          <tr>
            <td><strong>A.</strong></td>
            <td><strong>Loan Origination Charges</strong></td>
            <td style={{ textAlign: 'center' }}>&nbsp;</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Processing Fees</td>
            <td style={{ textAlign: 'center' }}>As Per Product & Borrower Profile</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Stamp Duty</td>
            <td style={{ textAlign: 'center' }}>As Per Applicable State Law</td>
          </tr>
          <tr>
            <td>3</td>
            <td>CERSAI Charge Creation Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 300/- Per Registration</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Legal Charges ( Including Title Investigation Report )</td>
            <td style={{ textAlign: 'center' }}>Upto Rs. 3,000/- Per Report</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Valuation Charges</td>
            <td style={{ textAlign: 'center' }}>Upto Rs. 3,000/- Per Report</td>
          </tr>
          <tr>
            <td>6</td>
            <td>RCU Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 1,200 Per Instance</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Additional Collateral Charges</td>
            <td style={{ textAlign: 'center' }}>Max. Rs. 3,500/- for each Additional Asset</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Insurance Charges</td>
            <td style={{ textAlign: 'center' }}>As Per Insurance Company Policy</td>
          </tr>
          <tr>
            <td><strong>B</strong></td>
            <td><strong>Loan Management Charges</strong></td>
            <td style={{ textAlign: 'center' }}>&nbsp;</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Bank Charges</td>
            <td style={{ textAlign: 'center' }}>As Per Actual</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Cheque/ACH/ECH Collection Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 500/- Per Instance</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Cheque Swap/Replacement Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 500/- Per Instance</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Collection Follow Up Telecalling Fees</td>
            <td style={{ textAlign: 'center' }}>Rs. 100/- Per Instance</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Field Visit Fees for Collection Follow Up</td>
            <td style={{ textAlign: 'center' }}>Rs. 500/- Per Instance</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Collection Communication Letter Fees</td>
            <td style={{ textAlign: 'center' }}>Rs. 200/- Per Instance</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Cash Collection Charge</td>
            <td style={{ textAlign: 'center' }}>Rs. 500/- Per Instance</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Reimbursement of Collection/ Travelling/ Legal/ Recovery/ Parking/ Repossession/ Other Charge</td>
            <td style={{ textAlign: 'center' }}>As Per Actual Expenditure</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Foreclosure Pre-Termination Charges</td>
            <td style={{ textAlign: 'center' }}>10% ( in Loan Against Property, MSME/SB Loans, Un secured Loans)</td>
          </tr>
          <tr>
            <td>18</td>
            <td>Foreclosure Value Statement Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 2000/- Per Instance</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Amortization Table Charge</td>
            <td style={{ textAlign: 'center' }}>Rs. 2000/- Per Instance</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Duplicate/ Additional NOC</td>
            <td style={{ textAlign: 'center' }}>Rs. 2000/- Per Instance</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Duplicate Repayment Schedule</td>
            <td style={{ textAlign: 'center' }}>Rs. 2000/- Per Instance</td>
          </tr>
          <tr>
            <td>22</td>
            <td>Loan Cancellation/Rebooking Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 5000/- for MSME/SB Loans, Loan Against Property</td>
          </tr>
          <tr>
            <td><strong>C</strong></td>
            <td><strong>Charges for Non Compliance of Terms & Conditions of Loan Agreement</strong></td>
            <td style={{ textAlign: 'center' }}>&nbsp;</td>
          </tr>
          <tr>
            <td>23</td>
            <td>Late Payment Interest ( for The Loans Disbursed till March 31, 2024 )</td>
            <td style={{ textAlign: 'center' }}>36% P.A. on Defaulted Amount for The Period of Default</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Overdue EMI Interest ( for The Loans to Be Disbursed on and After April 01, 2024 )</td>
            <td style={{ textAlign: 'center' }}>As Per Contractual Annualized Rate of Interest on Defaulted Amount for The Period of Dafault</td>
          </tr>
          <tr>
            <td>25</td>
            <td>Penal Charges ( For The Loans to be Disbursed on and After April 01, 2024)</td>
            <td style={{ textAlign: 'center' }}>24% P.A. on Defaulted Amount for The Period of Default</td>
          </tr>
          <tr>
            <td>26</td>
            <td>Cheque Bounce/ECH or ACH Dishonor Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 500/- Per Instance</td>
          </tr>
          <tr>
            <td>27</td>
            <td>EMI Default Charges</td>
            <td style={{ textAlign: 'center' }}>Rs. 250/- Per EMI for Each Completed Month</td>
          </tr>
        </tbody>
      </table>

      <p># All of above charges, duties & fees are exclusive of all types of taxes charged by Government, Semi-Government or any other concerned authorities. The Company has sole discretion to make any subsequent changes and/or modifications in any or all charges mentioned above, from time to time, as per Company Policy.</p>
    </div>
    </section>
    </div> 
{/* <section class="news_media_card-container">

	<div class="news_media_card">
  <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>
	
		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
  <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>
		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>	
  <div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>
  
	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>
		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>	
  <div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>
	<div class="news_media_card">
 <p className='span'>July 23, 2019</p>
		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>	
  <div class="news_media_card">
 <p className='span'>July 23, 2019</p>

		<p>TPG invests more money in kaabil Finance.</p>
    <button class="business_button">Know More</button>
	</div>

</section> */}


        <Footer/>

    </div>
  )
}

export default NewsMedia