import React,{useEffect} from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import Gold_loan from '../img/gold_loan.jpg'
import Main_loan from './Main_loan'
function GoldLoan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
         <Navbar/>
         <section class="landing" >
    <div class="landing-text">
      <h1>Gold Loan</h1>
      <p className='BusiessLoan-para'>
      Kaabil Finance offers hassle-free gold loans with competitive interest rates, providing quick financial solutions backed by the security of your gold assets. Unlock the value of your gold with Kaabil Finance's convenient and transparent gold loan services.
      </p>
     <button className='apply-bussiness-loan'>Apply Loan</button>
    </div>
    <div class="landing-image">
      <img src={Gold_loan} alt="Gold loan" className='Bussiness_loan_logo'/>
    </div>
  </section>
  <Main_loan/>
        <Footer/>
    </div>
  )
}

export default GoldLoan