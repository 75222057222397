// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hover-effect:hover {
  color: #e8c744;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.navbar {
  background-color: #013B81;
  transition: background-color 0.3s ease;
  z-index: 100;
}

.navbar.scrolled{
  position: fixed;
  width: 100%;
  top:0; 
  background-color: #ffffff; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.navbar.scrolled .hover-effect{
  /* Your scrolled navbar styles */
 color: #000000; 

}




.navbars {
  /* Your regular navbar styles */
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 100;
  position: absolute;
  width: 100%;
}
.navbars .hover-effect{
 color: #ffffff; 
}
.navbars .hover-effect:hover{
  color: #e8c744;
}
.navbars.scrolled{
  /* Your scrolled navbar styles */
  position: fixed;
  width: 100%;
  top:0; 
  background-color: #ffffff; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.navbars.scrolled .hover-effect{
  /* Your scrolled navbar styles */
 color: #000000; 

}

`, "",{"version":3,"sources":["webpack://./src/Components/nav/App.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,sCAAsC;EACtC,YAAY;AACd;;AAEA;EACE,eAAe;EACf,WAAW;EACX,KAAK;EACL,yBAAyB;EACzB,yCAAyC;EACzC,YAAY;AACd;AACA;EACE,gCAAgC;CACjC,cAAc;;AAEf;;;;;AAKA;EACE,+BAA+B;EAC/B,6BAA6B;EAC7B,sCAAsC;EACtC,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;AACA;CACC,cAAc;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,gCAAgC;EAChC,eAAe;EACf,WAAW;EACX,KAAK;EACL,yBAAyB;EACzB,yCAAyC;EACzC,YAAY;AACd;AACA;EACE,gCAAgC;CACjC,cAAc;;AAEf","sourcesContent":[".hover-effect:hover {\r\n  color: #e8c744;\r\n}\r\n* {\r\n  box-sizing: border-box;\r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: 'Open Sans', sans-serif;\r\n}\r\n\r\n.navbar {\r\n  background-color: #013B81;\r\n  transition: background-color 0.3s ease;\r\n  z-index: 100;\r\n}\r\n\r\n.navbar.scrolled{\r\n  position: fixed;\r\n  width: 100%;\r\n  top:0; \r\n  background-color: #ffffff; \r\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n  z-index: 100;\r\n}\r\n.navbar.scrolled .hover-effect{\r\n  /* Your scrolled navbar styles */\r\n color: #000000; \r\n\r\n}\r\n\r\n\r\n\r\n\r\n.navbars {\r\n  /* Your regular navbar styles */\r\n  background-color: transparent;\r\n  transition: background-color 0.3s ease;\r\n  z-index: 100;\r\n  position: absolute;\r\n  width: 100%;\r\n}\r\n.navbars .hover-effect{\r\n color: #ffffff; \r\n}\r\n.navbars .hover-effect:hover{\r\n  color: #e8c744;\r\n}\r\n.navbars.scrolled{\r\n  /* Your scrolled navbar styles */\r\n  position: fixed;\r\n  width: 100%;\r\n  top:0; \r\n  background-color: #ffffff; \r\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\r\n  z-index: 100;\r\n}\r\n.navbars.scrolled .hover-effect{\r\n  /* Your scrolled navbar styles */\r\n color: #000000; \r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
