import React ,{useEffect}from 'react'
import './tearms_conditions.css'
import Navbar from '../nav/Navbar';
import Footer from '../footer/Footer'
function Terms_and_conditions() {
   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar/>
       <div class="termsAndConditions fadeIn">
      <h1 class="termsAndConditionsHeading">Terms and Conditions</h1>
      <h4 class="spangleWelcome">Welcome to Kaabil Finance</h4>
      <p class="termsParagraphIntro">These Terms and Conditions of Use ("Terms") 
govern your access to and use of the Website. By accessing or using our Website, you agree to comply 
with and be bound by these Terms. Please read these Terms carefully before using our Website.

      </p>    
      <div class="serviceLeadingSection">
        <h4><span class="sn blue">1.</span><span class="st blue">Acceptance of Terms</span></h4>
        <p class="spl">By accessing or using Kaabil Finance's Website, you acknowledge that you have read, understood, and 
agree to be bound by these Terms. If you do not agree to these Terms, please refrain from using our 
Website.</p>                                
      </div>

      <div class="serviceLeadingSection">
        <h4><span class="sn orange">2.</span><span class="st orange">Use of Website</span></h4>
        <p class="spl">You agree to use Kaabil Finance's Website only for lawful purposes and in a manner that does not 
infringe upon the rights of others, restrict or inhibit their use and enjoyment of the Website. You may 
not use the Website in any manner that could damage, disable, overburden, or impair the Website or 
interfere with any other party's use or enjoyment of the Website.</p>                  
      </div>
      <div class="serviceLeadingSection">
        <h4><span class="sn lightGreen">3.</span><span class="st lightGreen">Ownership and Content</span></h4>
        <p class="spl">Kaabil Finance owns and maintains the Website to provide information about our services, facilities, and 
programs, and to facilitate communication with Kaabil Finance. All content, trademarks, logos, and 
intellectual property displayed on the Website, including but not limited to text, graphics, logos, button 
icons, images, audio clips, digital downloads, data compilations, and software, are the property of Kaabil 
Finance or its licensors and are protected by copyright and other intellectual property laws.</p>
      </div> 
       
      <div class="serviceLeadingSection">
        <h4><span class="sn purple">4.</span><span class="st purple">Eligibility for Services</span></h4>
        <p class="spl">Not all services, facilities, and programs offered by Kaabil Finance on the Website may be available to all 
users. Kaabil Finance reserves the right to determine eligibility for any service, facility, or program. The 
availability of services, facilities, and programs may vary depending on factors such as location, 
regulatory requirements, and other considerations.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn yellow">5.</span><span class="st yellow">Privacy and Data Usage</span></h4>
        <p class="spl">By providing your details to Kaabil Finance through the Website, you authorize us to use and process 
your information in accordance with our Privacy Policy. We may use your information to determine your 
eligibility for our services and to contact you for promotional purposes. Please refer to our Privacy Policy 
for more information on how we collect, use, and protect your personal information.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn red">6.</span><span class="st red">Disclaimer of Offer</span></h4>
        <p class="spl">The information provided on Kaabil Finance's Website is for general informational purposes only and 
should not be construed as an offer to provide any financial assistance or services. The terms and 
conditions of any services offered by Kaabil Finance may be subject to change without prior notice. Any 
descriptions of products or services provided on the Website are for informational purposes only and do 
not constitute offers to sell or solicitations to buy any products or services.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn lineColorpink">7.</span><span class="st lineColorpink">Intellectual Property Rights</span></h4>
        <p class="spl">All copyright, trademarks, and other intellectual property rights in the content on the Website, including 
but not limited to text, graphics, logos, button icons, images, audio clips, digital downloads, data 
compilations, and software, remain vested in Kaabil Finance or its licensors. You may not modify, 
reproduce, distribute, or use any content from the Website without prior written consent from Kaabil 
Finance. Any unauthorized use of the content may violate copyright, trademark, and other laws.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn pink">8.</span><span class="st pink">Limitation of Liability</span></h4>
        <p class="spl">Kaabil Finance does not warrant that the services and products offered on the Website will be 
uninterrupted or error-free. We shall not be liable for any damages or losses resulting from the use of or 
inability to use the Website. In no event shall Kaabil Finance or its affiliates, directors, officers, 
employees, or agents be liable for any direct, indirect, incidental, special, consequential, or punitive 
damages arising out of or in connection with the use of or inability to use the Website or any content on 
the Website.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn lineColorBlues">9.</span><span class="st lineColorBlues">Governing Law and Jurisdiction</span></h4>
        <p class="spl">These Terms shall be governed by and construed in accordance with the laws of [insert jurisdiction]. Any 
disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of 
the courts of [insert jurisdiction]. You agree to submit to the jurisdiction of such courts and waive any 
objection to the venue of any legal process in such courts on the basis of forum non conveniens or any 
other basis.</p>
      </div> 
      <div class="serviceLeadingSection">
        <h4><span class="sn lightblue">10.</span><span class="st lightblue">Modifications to Terms</span></h4>
        <p class="spl">Kaabil Finance reserves the right to revise these Terms at any time without prior notice. By continuing to 
use our Website after any changes are made, you agree to be bound by the revised Terms. It is your 
responsibility to review these Terms periodically for changes.</p>
      </div> 
    
      <h4 class="closeTerms">If you have any questions about these Terms and Conditions of Use, please contact us.</h4>
     
    </div>
   

    <Footer/>
   
    </div>
  )
}

export default Terms_and_conditions
