import React ,{useState}from 'react'
import Business_loan_image from '../img/business_loan_image.jpg'
import Home_loan from '../img/home_loan.jpg'
import gold_loan_image from '../img/gold_loan_image.png'
import Bussiness_loan from '../img/bussiness_loan.jpg'
function Main_loan() {
  return (
    <div>
          <div className="tabs">
      <Tabs>
      <a label="Overview"> </a>
          <a label="Features & Benefits"></a>
          <a label="Eligibility"></a>
          {/* <a label="Products"></a> */}
      </Tabs>

   
    </div>
   
    <div class="business_tab" id='Overview'>
    <div class="text">
    <h1 className='overview'>Overview</h1>
    <p>
    We understand how a four-wheeler of your own is not just a luxury today, but a necessity. We help you with personalized car loans to make your dreams come true. Are you a self-employed professional or entrepreneur? Do you hail from an urban centre or a rural area? Whatever be your profile and requirements, we are there to help. We sanction loans based on your income generation abilities, vehicle running experience, geographical aspects, asset holding and other factors. We make car ownership easy for everyone. Fulfil your dreams today with Kaabil Finance. Own what you do. Own your destiny.
    </p>
    </div>
    <div class="image"  >
        <img src={Bussiness_loan} alt="Your Image" id='img'/>
    </div>
</div>
<div class="business_tab1" id='Features_&_Benefits'>
    <div class="Features_Benefits_text">
    <h1 className='Features_Benefits' >Features & Benefits of Kaabil Finance Secured Business Loan</h1>
    <p>
    Our car loans are tailored for maximum comfort and convenience.
    </p>
    <button className='apply-bussiness-loan' style={{backgroundColor:"green"}}>Apply Loan</button>
    </div>
    <div class="Features_Benefits_text_right">
      <p> <i class="fa fa-check-square"></i> Loans for all owner profiles.</p>
      <p> <i class="fa fa-check-square"></i> Attractive interest rates.</p>
      <p> <i class="fa fa-check-square"></i> 100% transparency in loan process.</p>
      <p> <i class="fa fa-check-square"></i> Quick loan application and processing.</p>
      <p> <i class="fa fa-check-square"></i> Seamless loan disbursals.</p>
      <p> <i class="fa fa-check-square"></i> Easy EMI calculator.</p>
      <p> <i class="fa fa-check-square"></i> Convenient used-car financing options.</p>
    </div>
</div>
<div class="business_tab2" id='Eligibility'>
    <div class="text">
    <h1 className='overview' >Eligibility</h1>
    <p>
    Our eligibility criteria cover aspects like age, income, nature of income, and other aspects. We work out the best possible loan solution based on your eligibility.    </p>
    </div>
    <div class="image">
        <img src={Bussiness_loan} alt="Your Image" id='img'/>
    </div>
</div>
{/* <div class="business_tab3" id='Products'>
    <div class="Features_Benefits_text">
    <h1 className='Features_Benefits' style={{color:"#fff"}}>Other Products for You</h1>
    </div>
    <div class="business_wrapper">
<section class="business_columns">
	<div class="business_column">
		<h2>Secured Business Loan</h2>
      <img src={Business_loan_image} alt='business loan logo'/>
      <p>Powering the nation’s pillars</p>
    <button class="business_button">Know More</button>
	</div>
	<div class="business_column" >
		<h2>Home Loans</h2>
    <img src={Home_loan} alt='business loan logo'/>
    <p>Powering the nation’s pillars</p>
    <button class="business_button">Know More</button>
			</div>
  <div class="business_column">
 
		<h2>Gold Loan</h2>
     <img src={gold_loan_image} alt='gold loan logo'/>
     <p>Powering the nation’s pillars</p>
    <button class="business_button">Know More</button>
	</div>
</section>	
</div>

</div> */}
    </div>
  )
}
const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(children[0].props.label);
  
    const changeTab = (tab) => {
      setActiveTab(tab);
    };
  
    let content;
    let buttons = [];
  
    React.Children.forEach(children, (child) => {
      buttons.push(child.props.label);
      if (child.props.label === activeTab) content = child.props.children;
    });
  
    return (
      <div>
        <TabButtons activeTab={activeTab} buttons={buttons} changeTab={changeTab} />
        <div className="tab-content">{content}</div>
      </div>
    );
  };
  
  const TabButtons = ({ buttons, changeTab, activeTab }) => {
    return (
      <div className="tab-buttons">
        {buttons.map((button) => (
          <a
            href={`#${button.replace(/\s+/g, '_')}`} // Replace spaces with underscores in the label
            className={`main_loan_button ${button === activeTab ? 'loan_active' : ''}`}
            onClick={() => changeTab(button)}
            key={button}
          >
            {button}
          </a>
        ))}
      </div>
    );
  };

export default Main_loan