import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";
import './App.css'
const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link) => (
        <div >
          <div className="px-3 text-left md:cursor-pointer group">
            <h1
              className="hover-effect py-7 flex justify-between items-center md:pr-0 pr-5 group"
              
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
              style={{fontWeight:"bold"}}
            >
              {link.name}
              <span className="text-xl md:hidden inline">
                <ion-icon 
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                >
                </ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </h1>
            {link.submenu && (
              <div>
                <div className="absolute top-20 hidden group-hover:md:block hover:md:block">
                  {/* <div > */}
                  {/* <div className="py-3"> */}
                    {/* <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 rotate-45"
                    style={{backgroundColor:"red"}}></div> */}
                
                  {/* </div> */}
                  <div style={{marginLeft:"-10rem",marginTop:"-30px"}}>
                  {/* <div style={{marginLeft:"-18rem"}}> */}
                    {link.sublinks.map((mysublinks) => (
                      <div style={{display:"flex"}}>
                        {mysublinks.sublink.map((slink) => (
                          <li style={{paddingLeft:"0.7rem"}} className="hover-effect text-sm text-white-600 my-2.5">
                            <a href={slink.link}>{slink.name}</a>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks) => (
              <div>
                <div>
                    {slinks.sublink.map((slink) => (
                      <li className="py-3 pl-14">
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
