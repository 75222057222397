import React,{useEffect} from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import './loans.css'
import Bussiness_loan from '../img/bussiness_loan.jpg'
import Main_loan from './Main_loan'
import { Link } from 'react-router-dom'
import '../about/about.css'
function SecuredBusinessLoan() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
        <Navbar/>
        <section class="landing" >
    <div class="landing-text">
      <h1 style={{fontSize:"38px"}} id="colorRed">Secured Business Loans (SBL)</h1>
      <p className='BusiessLoan-para'  style={{marginTop:"2rem",textAlign:"justify"}}>
      At <span style={{color:"#002E6E",fontWeight:"bold"}}>Kaabil Finance Private Limited</span> <i>(KFPL)</i>, our <span style={{color:"#002E6E",fontWeight:"bold"}}>Secured Business Loans </span>(SBL) are crafted to provide essential financial support to micro-level enterprises across various regions. We understand the unique needs of small businesses, and our SBL product is tailored to offer flexible, accessible, and reliable funding solutions.

      </p>
     {/* <button className='apply-bussiness-loan'><Link to="/contact-us">Apply Loan</Link></button> */}
    </div>

    <div class="landing-image">
      <img src={Bussiness_loan} alt="Bussiness loan" className='Bussiness_loan_logo'/>
    </div>
  </section>
  <div className='about_us'>
      <section style={{marginTop:"3rem"}}>
      <h1 id="colorRed">What is a Secured Business Loan (SBL)?</h1>
      <p style={{textAlign:"justify"}}>A <span style={{color:"#002E6E",fontWeight:"bold"}}>Secured Business Loan</span> is a type of financing where the borrower pledges collateral to secure the loan. This collateral acts as a safety net for the lender, enabling us to offer competitive loan terms and conditions to businesses in need of capital for growth, operations, or other financial needs.</p>  
<h1 id="colorRed">Key Features of Our Secured Business Loans (SBL)</h1>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Flexible Loan Amounts:</span> Access financing tailored to your business needs, whether you require funds for daily operations, expansion, or significant investments.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Competitive Interest Rates:</span> Enjoy attractive interest rates that make borrowing more affordable and help you manage your business finances effectively.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Streamlined Application Process:</span> Benefit from a straightforward application process with minimal paperwork, ensuring a quick and efficient way to secure the financing you need.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Dedicated Support:</span> Receive personalized assistance from our experienced team, who are committed to understanding your business and providing support throughout the loan process.</p>

    </section>
    <section style={{marginTop:"3rem"}}>
      
<h1 id="colorRed">Benefits of Secured Business Loans (SBL):</h1>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Increased Borrowing Capacity: </span> Providing collateral allows you to access larger loan amounts and better terms compared to unsecured loans.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Business Growth:</span> Use the loan funds to invest in growth opportunities, upgrade equipment, manage working capital, or expand operations.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Financial Stability:</span>Strengthen your business’s financial health with reliable funding that supports long-term success.</p>
    </section>
    <section style={{marginTop:"3rem"}}>
<h1 id="colorRed">How to Apply</h1>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Get in Touch:</span> Contact our team to discuss your business needs and explore how our Secured Business Loans (SBL) can support your goals.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Submit Your Application:</span>Complete a simple application form with details about you, your business and collateral you wish to offer.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Review and Approval:</span>Our team will review your application, assess your profile and collateral to provide a customized loan proposal which suits your requirement.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Receive Funds:</span>Upon approval, get the funds you need quickly and efficiently into your bank account to advance your business objectives.</p>

    </section>
    <section style={{marginTop:"3rem"}}>
<h1 id="colorRed">Hassle-free digital repayment options</h1>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Enach:</span>Enable enach at the time of disbursement and ensure that you keep balance on the EMI due date.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>UPI:</span>Each loan is enabled with unique QR code which can be scanned and payment can be done using any UPI app. Payment directly accounts for your loan number which reduces reconciliation issues for you.</p>
<p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Approachable team:</span>Our team will be available at all points to share details and help you in case if you face any problems in payment.</p>
    </section>
    <p><span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Note : </span> <i> We would request all our customers to ensure that they take receipt of payment if paid at branch or to collection executive without fail. Kindly call on 8690008282 or write to care@kaabilfinance.in in case branch or collection executive refrains from not sharing proper receipt of payment</i></p>
    <section style={{marginTop:"3rem"}}>
<h1 id="colorRed">   Unlock your business potential </h1>
<p>Contact us today to learn more for <span style={{color:"#002E6E",fontWeight:"bold",textAlign:"justify"}}>Kaabil secured business loan</span>(SBL) and take the next step toward growth and success!</p>

    </section>
    </div> 

   
 


{/* <Main_loan/> */}


        <Footer/>

    </div>
  )
}


export default SecuredBusinessLoan


