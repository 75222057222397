import React,{useEffect} from 'react'
import Navbar from '../nav/Navbar'
import Footer from '../footer/Footer'
import Main_loan from './Main_loan'
function LoanAgainstProperty() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
           <Navbar/>
           <div >
           LoanAgainstProperty
           <Main_loan/>
           </div>
         
        <Footer/>

    </div>
  )
}

export default LoanAgainstProperty