import React from 'react';
import {BrowserRouter,Routes,Route}  from 'react-router-dom';
import Home from './Components/home/Home';
import SecuredBusinessLoan from './Components/loans/SecuredBusinessLoan';
import LoanAgainstProperty from './Components/loans/LoanAgainstProperty';
import GoldLoan from './Components/loans/GoldLoan';
import NewsMedia from './Components/news_media/NewsMedia';
import Careers from './Components/careers/Careers';
import WhoWeAre from './Components/about/WhoWeAre';
import ContactUs from './Components/contact_us/ContactUs';
import Termsconditions from './Components/terms_conditions/Terms_conditions';
import PrivacyPolicy from './Components/privacy/Privacy_Policy';
import Schedule_of_charges from './Components/schedule_of_charges/Schedule_of_charges'
import Gallery from './Components/gallery/Gallery';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/secure-business-loan" element={<SecuredBusinessLoan/>}/>
      <Route path="/loan-against-property" element={<LoanAgainstProperty/>}/>
      <Route path="/gold-loan" element={<GoldLoan/>}/>
      <Route path="/news-media" element={<NewsMedia/>}/>
      <Route path="/careers" element={<Careers/>}/>
      <Route path="/who-we-are" element={<WhoWeAre/>}/>
      <Route path="/contact-us" element={<ContactUs/>}/>
      <Route path="/terms-and-conditions" element={<Termsconditions/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
      <Route path="/Schedule-of-charges" element={<Schedule_of_charges/>}/>
      <Route path="/gallery" element={<Gallery/>}/>
    </Routes>
    </BrowserRouter>
  )
}

export default App